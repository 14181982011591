import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'

import GoogleMapReact from 'google-map-react'

const Marker = ({ text }) => (
  <div
    sx={{
      width: '15px',
      height: '15px',
      color: 'white',
      border: '1px solid',
      borderColor: 'blue.0',
      bg: 'blue.2',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {!!text && text}
  </div>
)

export class Map extends React.Component {
  static defaultProps = {
    lat: 53.58152,
    lng: -2.4444,
    zoom: 14,
    width: '100%',
    height: '400px',
  }

  render() {
    return (
      <div style={{ width: this.props.width, height: this.props.height }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLE_MAPS_KEY,
          }}
          defaultCenter={{ lat: this.props.lat, lng: this.props.lng }}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={this.props.lat} lng={this.props.lng} />
        </GoogleMapReact>
      </div>
    )
  }
}
