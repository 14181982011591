import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Map from '../components/Map'
import Form from '../components/Form'
import { PageHeader } from '../types/definitions'
import { Grid, Box, Styled } from 'theme-ui'
import { css } from '@emotion/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

type ContactPageProps = {
  data: {
    page: {
      frontmatter: {
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
    }
  }
}

const ContactPage: FunctionComponent<ContactPageProps> = ({ data }) => {
  const { header, seo, footer } = data.page.frontmatter

  return (
    <Layout header={header} footer={footer} seo={seo}>
      <Container bg="white">
        <Grid gap={4} columns={[1, 2]}>
          <Box>
            <Map />
          </Box>
          <Box>
            <Styled.p
              css={css`
                margin-top: 0;
              `}
            >
              <strong>Telephone:</strong>{' '}
              <OutboundLink
                eventAction="tap"
                eventCategory="call_clicks"
                eventLabel="contact_call"
                href="tel:01204389006"
                sx={{ color: 'blue.2', textDecoration: 'none' }}
              >
                01204 389006
              </OutboundLink>
            </Styled.p>
            <Styled.p>
              <strong>E-mail:</strong>{' '}
              <OutboundLink
                eventAction="tap"
                eventCategory="email_clicks"
                eventLabel="contact_page_email"
                href="mailto:office@accountablebookkeeping.co.uk"
                sx={{ color: 'blue.2', textDecoration: 'none' }}
              >
                office@accountablebookkeeping.co.uk
              </OutboundLink>
            </Styled.p>
            <Form submitUrl="https://formspree.io/xpzyjnbp" />
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query Contact {
    page: mdx(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        footer {
          showCTA
        }
        ...header
        seo {
          description
          title
        }
      }
    }
  }
`
