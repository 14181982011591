import React, { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
/** @jsx jsx */
import { Box, jsx, Flex, Label, Input, Textarea, Button } from 'theme-ui'
import Card from '../Card'

type Inputs = {
  firstName: string
  lastName: string
  email: string
  telephone: string
  message?: string
}

type FormProps = {
  submitUrl: string
}

export const Form: FunctionComponent<FormProps> = ({ submitUrl }) => {
  const [isSending, setIsSending] = useState(false)

  const [hasSubmitError, setHasSubmitError] = useState(false)
  const [hasSubmitSuccess, setHasSubmitSuccess] = useState(false)
  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = (data) => {
    setIsSending(true)

    fetch(submitUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        setHasSubmitError(false)
        setHasSubmitSuccess(true)
      })
      .catch(() => setHasSubmitError(true))
      .finally(() => setIsSending(false))
  }

  return (
    <React.Fragment>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex sx={{ flexDirection: ['column', 'row'] }}>
          <Box sx={{ width: ['100%', '50%'], pr: [0, 2], mt: [2, 0] }}>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              name="firstName"
              id="firstName"
              ref={register({ required: true })}
              mt={2}
            />
            {errors.firstName && <span>Last name is required</span>}
          </Box>
          <Box sx={{ width: ['100%', '50%'], pl: [0, 2], mt: [2, 0] }}>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              name="lastName"
              id="lastName"
              ref={register({ required: true })}
              mt={2}
            />
            {errors.lastName && <span>Last name is required</span>}
          </Box>
        </Flex>

        <Flex sx={{ flexDirection: ['column', 'row'], mt: [0, 2] }}>
          <Box sx={{ width: ['100%', '50%'], pr: [0, 2], mt: [2, 0] }}>
            <Label htmlFor="email">Email</Label>
            <Input
              name="email"
              id="email"
              type="email"
              mt={2}
              ref={register({ required: true })}
            />
            {errors.email && <span>Email is required</span>}
          </Box>
          <Box sx={{ width: ['100%', '50%'], pl: [0, 2], mt: [2, 0] }}>
            <Label htmlFor="telephone">Telephone</Label>
            <Input
              name="telephone"
              id="telephone"
              ref={register({ required: true })}
              mt={2}
            />
            {errors.telephone && <span>Telephone is required</span>}
          </Box>
        </Flex>
        <Box mt={3}>
          <Label htmlFor="message">Message</Label>
          <Textarea
            mt={2}
            name="message"
            id="message"
            rows="10"
            ref={register}
          />
        </Box>

        <Button
          variant="defaultSmall"
          type="submit"
          mt={3}
          disabled={isSending}
        >
          {isSending ? 'Sending...' : 'Send'}
        </Button>
      </Box>
      <Box mt={3}>
        {hasSubmitError && (
          <Card background="warn" contentColor="white">
            There has been an error processing your request, please try again.
            If the issue persists please call us.
          </Card>
        )}
        {hasSubmitSuccess && (
          <Card background="green" contentColor="white">
            Your message has been sent successfully, one of our team members
            will be in touch shortly.
          </Card>
        )}
      </Box>
    </React.Fragment>
  )
}
